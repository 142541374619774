import React from 'react';
import { getStyle } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';

export default function TaskMultiSelectActionBar({ selectedTasks }) {

  return (
    <CBMediaQuery>
      {screenSize => (
        <div style={getStyle(screenSize, styles, 'container')}>
          <div style={getStyle(screenSize, styles, 'filterContainer')}>
            {
              selectedTasks.length > 0 && (
                <p>{selectedTasks.length} valittu</p>
              )
            }
          </div>
        </div>
      )}
    </CBMediaQuery>
  )
}


const styles = {
  default: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
      padding: 8,
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  small: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      padding: 8,
    },
  }
}