import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import {
  createVehicleMileage as _createVehicleMileage,
  updateVehicleMileage as _updateVehicleMileage
} from '../../../../actions/vehicleActions';

import moment from 'moment';
import { finnishDateFormat, getStyle } from '../../../../utils/utils';
import CBDatePickerInput from '../../../shared/CBDatePickerInput';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBModal from '../../../shared/CBModal';
import CBInputField from '../../../shared/form_components/CBInputField';

class AddMileageModal extends Component {
  constructor(props) {
    super(props);
    const { mileage } = props;
    this.state = {
      formData: {
        value: (mileage && mileage.value) || null,
        read_at: (mileage && mileage.read_at) || null,
      },
      formValid: {
      },
    };
  }

  componentDidMount = () => {
    const { formData } = this.state;
    const newState = {
      formData: {
        ...formData,
      },
    };

    this.setState(newState);
  };

  getIsValid = () => {
    const { formData } = this.state;
    const { value, read_at } = formData;

    return (value && read_at) || (!value && !read_at);
  };

  updateFormState = changedObject => {
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({ formData: newFormData });
  };

  submitForm = () => {
    const { formData } = this.state;
    const {
      closeModal,
      createVehicleMileage,
      updateVehicleMileage,
      mileage,
      vehicle,
      selectedOrganizationId,
      selectedWorkshopId
    } = this.props;

    const data = {
      ...formData,
      vehicle: vehicle.id,
      organization_id: selectedOrganizationId,
    }

    if (!mileage) {
      createVehicleMileage(data, selectedWorkshopId);
    } else {
      updateVehicleMileage(mileage.id, data, selectedWorkshopId);
    }

    closeModal();
  };

  closeModal = () => {
    const {
      closeModal,
    } = this.props;

    closeModal();
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen,
      afterOpenModal,
      t,
      vehicle,
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={this.closeModal}
            title={t('manualKmModalTitle')}
            descriptionText={t('manualKmModalDescription')}
            cancelText={t('cancel')}
            submitText={t('save')}
            vehicleText={vehicle.registration_number}
            submitForm={this.submitForm}
            isValid={this.getIsValid()}
          >
            <div style={getStyle(screenSize, styles, 'bodyContainer')}>

              <CBInputField
                name='value'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.value}
                placeholder=''
                type='number'
                onChange={this.updateFormState}
                isValidCallback={this.setIsValid}
                labelText={t('manualKmLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />

              <div style={getStyle(screenSize, styles, 'inputContainer')}>
                <p
                  className='font-titillium-web-semi-bold'
                  style={getStyle(screenSize, styles, 'dateInputLabel')}
                >
                  {t('manualKmDateLabel')}
                </p>
                <div className='datepicker-container-white-bg'>
                  <CBDatePickerInput
                    displayedDate={formData.read_at ? moment(formData.read_at) : null}
                    type='date'
                    dateFormat={finnishDateFormat}
                    changeDate={(value) => {
                      this.updateFormState({ target: 'read_at', value: value ? moment(value).format('YYYY-MM-DD') : null});
                    }}
                  />
                </div>
              </div>


            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    user: state.authUser.user,
    selectedOrganizationId: state.organization.selectedOrganization,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    createVehicleMileage: _createVehicleMileage,
    updateVehicleMileage: _updateVehicleMileage
  },
)(translate('VehicleDetailsView')(AddMileageModal));
