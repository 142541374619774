import React from 'react';
import { translate } from 'react-i18next';
import { getStyle, styleMerge } from '../../../utils/utils';
import CBButtonGarageAction from '../../shared/CBButtonGarageAction';
import CBDropzone from '../../shared/CBDropzone';
import CBMediaQuery from '../../shared/CBMediaQuery';


class MileageEditRow extends React.Component {
  state = {
  }

  render() {
    const {
      t,
      openDeleteMileageModal,
      editMileage,
      openStatusModal,
      mileage,
      isDone
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxContainer')}>
            <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxLeftContainer')}>
            </div>
            {
              mileage.can_edit && (
                <div className='font-titillium-web' style={getStyle(screenSize, styles, 'boxRightContainer')}>
                  <CBButtonGarageAction
                    mainIcon='/img/icon_trashcan.svg'
                    text='Poista'
                    onClick={openDeleteMileageModal}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                  <CBButtonGarageAction
                    mainIcon='/img/icon_edit.svg'
                    text='Muokkaa'
                    onClick={editMileage}
                    additionalStylesMainIcon={{ height: 25, width: 25 }}
                  />
                </div>
              )
            }
          </div>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    boxContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    boxLeftContainer: {
      display: 'flex',
    },
    boxRightContainer: {
      display: 'flex',
    },
  },
};

export default translate('VehicleDetailsView')(MileageEditRow);
