import React, { createRef } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  deleteVehicleMileage as _deleteVehicleMileage,
} from '../../../actions/vehicleActions';
import { formatDate, getStyle, SERVICE_WORKSHOP } from '../../../utils/utils';
import CBMediaQuery from '../../shared/CBMediaQuery';
import CBSpinner from '../../shared/CBSpinner';
import TextWithLinebreaks from '../../shared/TextWithLinebreaks';
import MileageEditRow from './MileageEditRow';

import CBPromptModal from '../../shared/CBPromptModal';
import AddMileageModal from './Modal/AddMileageModal';
import InfoboxWithIcon from './ListItems/InfoboxWithIcon';


class VehicleDetailsMileageContent extends React.Component {
  state = {
    deleteModalIsOpen: false,
    modalIsOpen: false,
  }

  constructor(props) {
    super(props);
  }

  deleteVehicleMileage = () => {
    const { deleteVehicleMileage, match, vehicle, service, selectedWorkshopId } = this.props;
    this.setState({
      deleteModalIsOpen: false,
    });
    let mileageId = null;
    if (match) {
      mileageId = match.params.mileageId;
    } else if (vehicle && vehicle.mileages.length) {
      mileageId = vehicle.mileages[0].id
    }
    if (service === SERVICE_WORKSHOP) {
      deleteVehicleMileage(mileageId, selectedWorkshopId);
    } else {
      deleteVehicleMileage(mileageId);
    }
  };


  getMileage = () => {
    const { vehicle, match } = this.props;
    const mileages = vehicle ? vehicle.mileages : [];
    let mileage = match
      ? mileages.find(x => x.id == match.params.mileageId)
      : undefined;
    if (!mileage && vehicle && mileages.length) {
      mileage = mileages[0];
    }
    return mileage;
  }

  openDeleteMileageModal = () => {
    this.setState({
      deleteModalIsOpen: true,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalIsOpen: false,
    });
  };

  afterOpenDeleteModal = () => {
  };

  editMileage = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getPriorityText = (priority) => {
    const {
      t,
    } = this.props;

    switch(priority) {
      case 'high':
        return t('mileagePriorityHigh');
      case 'medium':
        return t('mileagePriorityMedium');
      case 'low':
        return t('mileagePriorityLow');
      default:
        return '';
    }
  };

  render() {
    const {
      vehicleRequest, t, match, vehicle,
    } = this.props;

    const {
      modalIsOpen,
      deleteModalIsOpen,
      imageIndex,
      imageModalIsOpen,
      statusModalIsOpen
    } = this.state;

    const mileage = this.getMileage();

    if (vehicleRequest.mileageStatus.loading) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'mileageContentContainer')}>
              <div>
                <CBSpinner />
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else if (vehicleRequest.mileageStatus.error) {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'mileageContentContainer')}>
              <div
                className='font-titillium-web-extra-light'
                style={getStyle(screenSize, styles, 'sectionHeading')}
              >
                <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                  Ajokilometrikirjausta ei löydy.
                </div>
              </div>
            </div>
          )}
        </CBMediaQuery>
      );
    } else {
      return (
        <CBMediaQuery>
          {screenSize => (
            <div style={getStyle(screenSize, styles, 'mileageContentContainer')}>
              {mileage && (
                <React.Fragment>
                  <div>
                    <div style={getStyle(screenSize, styles, 'topInfoContainer')}>
                      <InfoboxWithIcon
                        icon='/img/icon-wheel.svg'
                        text={mileage.value}
                        labelText={t('mileageValue')}
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_calendar.svg'
                        text={formatDate(mileage.read_at)}
                        labelText={t('mileageReadAt')}
                        hasBorders
                      />
                      <InfoboxWithIcon
                        icon='/img/icon_task_origin.svg'
                        text={t(mileage.source)}
                        labelText={t('mileageSource')}
                      />
                    </div>
                    <div style={getStyle(screenSize, styles, 'mainContentContainer')}>
                      <div
                        className='font-titillium-web-extra-light'
                        style={getStyle(screenSize, styles, 'sectionHeading')}
                      >
                        <div style={getStyle(screenSize, styles, 'sectionHeadingText')}>
                          {mileage.title}
                        </div>

                        <div style={getStyle(screenSize, styles, 'additionalMileageInfo')}>
                          <div style={getStyle(screenSize, styles, 'creatorContainer')}>
                            {`${t('taskUserLabel')}: ${mileage.user_display}`}
                          </div>
                          <div>
                            {`${t('taskCreatedLabel')}: ${moment(mileage.created_at).format('D.M.YYYY HH:mm')}`}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={getStyle(screenSize, styles, 'mileageTextContainer')}>
                          <p style={getStyle(screenSize, styles, 'treatmentDescriptionText')}>
                            <TextWithLinebreaks text={''} />
                          </p>
                        </div>
                        <MileageEditRow
                          mileage={mileage}
                          openDeleteMileageModal={this.openDeleteMileageModal}
                          editMileage={this.editMileage}
                          openStatusModal={this.openStatusModal}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    modalIsOpen && (
                      <AddMileageModal
                        modalIsOpen={modalIsOpen}
                        closeModal={this.closeModal}
                        afterOpenModal={this.afterOpenModal}
                        mileage={mileage}
                        vehicle={vehicle}
                      />
                    )
                  }
                  <CBPromptModal
                    modalIsOpen={deleteModalIsOpen}
                    closeModal={this.closeDeleteModal}
                    afterOpenModal={this.afterOpenDeleteModal}
                    title={t('deleteMileagePromptTitle')}
                    text={t('deleteMileagePromptText')}
                    buttonText={t('deleteMileagePromptButton')}
                    performAction={this.deleteVehicleMileage}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </CBMediaQuery>
      );
    }
  }
}

const styles = {
  default: {
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    mileageNameText: {
      fontSize: 32,
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 32,
    },
    squareImage: {
      width: 100,
      height: 100,
      margin: '0 10px 10px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    galleryContainer: {
      marginBottom: 16,
    },
    responseButtonContainer: {
      width: 200,
      marginBottom: 32,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    mileageDescriptionText: {
      fontSize: 14,
    },
    galleryThumbnailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    thumbnail: {
      maxWidth: 120,
      cursor: 'pointer',
    },
    thumbnailContainer: {
      marginRight: 8,
    },
    responseContainer: {
      marginTop: 32,
    },
    sectionHeadingText: {
      fontSize: 24,
    },
    responseHeading: {
      fontSize: 20,
      color: 'white',
    },
    mileageTextContainer: {
      marginBottom: 16,
    },
    sectionHeading: {
      display: 'flex',
      fontSize: 20,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'white',
      flexWrap: 'wrap',
      marginBottom: 16,
      marginTop: 16,
    },
    additionalMileageInfo: {
      display: 'flex',
      fontSize: 12,
      color: 'white',
    },
    treatmentDescriptionText: {
      fontSize: 14,
      minWidth: '100%',
      width: 0,
      overflowWrap: 'break-word',
    },
    creatorContainer: {
      marginRight: 16,
    },
    mileageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 1)',
      minHeight: 500,
      width: '75%',
      padding: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginLeft: -16,
      marginRight: -16,
    },
  },
  small: {
    mileageContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      backgroundColor: 'auto',
      minHeight: 500,
      width: '100%',
      padding: 16,
    },
    mainContentContainer: {
      backgroundColor: 'rgba(0, 149, 218, 1)',
      margin: -16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    topInfoContainer: {
      display: 'flex',
      justifyContent: 'normal',
      flexDirection: 'column',
      backgroundColor: 'rgba(0, 149, 218, 0.4)',
    },
  },
};

function mapStateToProps(state) {
  return {
    vehicleRequest: state.vehicle,
    searchRequest: state.search,
    service: state.app.service,
    selectedWorkshopId: state.workshop.selectedWorkshop,
  };
}

export default connect(
  mapStateToProps,
  {
    deleteVehicleMileage: _deleteVehicleMileage,
  },
)(translate('VehicleDetailsView')(VehicleDetailsMileageContent));
