import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { getStyle, getModalStyle } from '../../../../utils/utils';
import {
  updateVehicle as _updateVehicle,
  updateOrganizationVehicle as _updateOrganizationVehicle,
} from '../../../../actions/vehicleActions';
import CBMediaQuery from '../../../shared/CBMediaQuery';
import CBButtonSubmit from '../../../shared/CBButtonSubmit';
import CBInputField from '../../../shared/form_components/CBInputField';
import { getLengthValidator } from '../../../../utils/validators';
import CBModal from '../../../shared/CBModal';

Modal.setAppElement('#root');

class EditVehicleTopInfoModal extends Component {
  state = {
    formData: {
      year_model: '',
      owner_description: '',
      organization_data: {
        identifier: '',
        maintenance_interval_months: 0,
        maintenance_interval_km: 0,
      }
    },
  };

  componentDidMount = () => {
    const { vehicle } = this.props;
    const { formData } = this.state;
    const newFormData = Object.assign({}, formData);

    if (vehicle.year_model) {
      newFormData.year_model = vehicle.year_model;
    }

    if (vehicle.owner_description) {
      newFormData.owner_description = vehicle.owner_description;
    }

    if (vehicle.organization_data) {
      newFormData.organization_data.identifier = vehicle.organization_data.identifier;
      newFormData.organization_data.maintenance_interval_months = vehicle.organization_data.maintenance_interval_months;
      newFormData.organization_data.maintenance_interval_km = vehicle.organization_data.maintenance_interval_km;
    }

    this.setState({
      formData: newFormData
    });
  };

  setIsValid = (name, isValid) => {
    const { formValid } = this.state;
    const newFormValid = {
      ...formValid,
      [name]: isValid,
    };

    this.setState({ formValid: newFormValid });
  };

  getIsValid = () => {
    const { formValid } = this.state;
    let isValid = true;
    Object.values(formValid).forEach(fieldIsValid => {
      if (!fieldIsValid) {
        isValid = false;
      }
    });
    return isValid;
  };

  updateFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData[changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  updateOrganizationDataFormState = changedObject => {
    const {
      formData
    } = this.state;
    const newFormData = Object.assign({}, formData);
    newFormData['organization_data'][changedObject.target] = changedObject.value;
    this.setState({
      formData: newFormData
    });
  };

  onSubmit = () => {
    const {
      vehicle,
      updateVehicle,
      updateOrganizationVehicle,
      isOrganizationVehicle,
      selectedOrganizationId,
    } = this.props;
    const { formData } = this.state;

    const copyFormData = Object.assign({}, formData);

    if (vehicle) {
      if (isOrganizationVehicle) {
        updateOrganizationVehicle(vehicle.id, copyFormData, selectedOrganizationId);
      } else {
        delete copyFormData.organization_data;
        updateVehicle(vehicle.id, copyFormData);
      }
    }
  };

  render() {
    const { formData } = this.state;
    const {
      modalIsOpen, closeModal, afterOpenModal, t, isOrganizationVehicle
    } = this.props;

    return (
      <CBMediaQuery>
        {screenSize => (
          <CBModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            title={t('vehicleTopInfoModalHeading')}
            cancelText={t('cancel')}
            submitText={t('save')}
            submitForm={this.onSubmit}
            isValid={true}
            >
            {
              isOrganizationVehicle && (
                <div style={getStyle(screenSize, styles, 'bodyContainer')}>
                  <p style={getStyle(screenSize, styles, 'subHeading')}>{t('organizationDataLabel')}</p>
                  <CBInputField
                    name='identifier'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={formData.organization_data.identifier}
                    onChange={this.updateOrganizationDataFormState}
                    labelText={t('identifierLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                  <CBInputField
                    name='maintenance_interval_months'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={formData.organization_data.maintenance_interval_months}
                    type='number'
                    onChange={this.updateOrganizationDataFormState}
                    labelText={t('mainentanceIntervalMonthsLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                  <CBInputField
                    name='maintenance_interval_km'
                    inputStyle={getStyle(screenSize, styles, 'input')}
                    value={formData.organization_data.maintenance_interval_km}
                    type='number'
                    onChange={this.updateOrganizationDataFormState}
                    labelText={t('mainentanceIntervalKmLabel')}
                    labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
                  />
                </div>
              )
            }

            <div style={getStyle(screenSize, styles, 'bodyContainer')}>
              <p style={getStyle(screenSize, styles, 'subHeading')}>{t('modelDataLabel')}</p>
              <CBInputField
                name='year_model'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.year_model}
                type='number'
                placeholder={t('yearModelPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 4)]}
                isValidCallback={this.setIsValid}
                labelText={t('yearModelLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
              <CBInputField
                name='owner_description'
                inputStyle={getStyle(screenSize, styles, 'input')}
                value={formData.owner_description}
                placeholder={t('vehicleOwnerDescriptionPlaceholder')}
                onChange={this.updateFormState}
                validators={[getLengthValidator(0, 21)]}
                isValidCallback={this.setIsValid}
                labelText={t('vehicleOwnerDescriptionLabel')}
                labelTextStyle={getStyle(screenSize, styles, 'inputLabelText')}
              />
            </div>
          </CBModal>
        )}
      </CBMediaQuery>
    );
  }
}

const styles = {
  default: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 32px'
    },
    subHeading: {
      color: 'black',
      textAlign: 'center',
      fontSize: 18,
    },
    datepickerContainer: {
      display: 'flex',
    },
    dateInputLabel: {
      color: 'black',
      textAlign: 'center'
    },
    input: {
      backgroundColor: '#ebebeb',
      fontSize: 14,
      textAlign: 'center',
      resize: 'none',
      color: 'black',
      borderRadius: 100,
      height: 40,
      border: 'none',
      width: '100%',
    },
    inputLabelText: {
      color: 'black',
    },
  },
  small: {
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 16px'
    }
  },
};

function mapStateToProps(state) {
  return {
    vehiclePermissionRequest: state.vehiclePermission,
    user: state.authUser.user,
    vehicle: state.vehicle.vehicle,
    selectedOrganizationId: state.organization.selectedOrganization,
  };
}

export default connect(
  mapStateToProps,
  {
    updateVehicle: _updateVehicle,
    updateOrganizationVehicle: _updateOrganizationVehicle,
  },
)(translate('VehicleDetailsView')(EditVehicleTopInfoModal));
